.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiperContainer {
  --swiper-navigation-sides-offset: 0rem;
  padding: 0 2rem;
  padding-bottom: 2rem;
}

.banners {
  height: 100%;
  width: 100%;
  padding: 2rem 0;
  max-width: 1360px;

  .slide {
    width: 100%;
    aspect-ratio: 16/9;
    cursor: grab;
    border: 1px solid var(--c-border);

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 1279px) {
  .banners {
    padding: 1rem 0;
  }

  .swiperContainer {
    padding: 0;
    padding-bottom: 2rem;
  }
}
