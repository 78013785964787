:root {
  --c-primary: #ff7538;
  --c-primary-light: #ffebe0;
  --c-bg: #ffffff;

  --c-font-dark: #484848;
  --c-font: #888888;
  --c-font-light: #b8b8b8;
  --c-font-light1: #b4b4b4;

  --c-orange: #ff7538;
  --c-orange-grad: linear-gradient(270deg, #ff9b52 0%, #fe5858 100%);
  --c-red: #f5222d;

  --c-white: #ffffff;
  --c-white-light: rgba(255, 255, 255, 0.8);
  --c-black: #181818;

  --c-border: #e8e8e8;
  --c-border-dark: #d8d8d8;

  --c-light: #f8f8f8;
  --c-light-1: #b8b8b8;
  --c-light-2: #f8f8f8;

  --c-dark: #484848;
  --c-dark-1: #0d1117;
  --c-dark-2: #24282e;

  --c-purple: #b04ddf;
  --c-shadow: rgba(0, 0, 0, 0.2);

  --c-link: #51a5ff;
  --c-blue: #407bd4;
  --c-green: #73d13d;
  --c-green-light: #e4f3ed;

  --message-green: #73d13d;
  --message-red: #ff4d4f;
  --message-yellow: #faad14;
  --message-blue: #40a9ff;
}
