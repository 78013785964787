.part6 {
  width: 100%;

  & > div {
    background: var(--c-white);
    padding: 1rem !important;
    border-radius: 0.5rem;
    margin: 1rem 0;
    border: 0.1rem solid var(--c-primary);
    display: flex;
    flex-direction: column;
    box-shadow: 2px 4px 15px var(--c-primary-light);

    & > div {
      font-size: 1rem;
      font-weight: 500;
      display: flex;

      .left {
        display: flex;
        justify-content: space-between;
        min-width: 25%;

        & > div:nth-child(2) {
          padding-left: 0.5rem;
        }
      }

      .right {
        color: var(--c-black);
        padding-left: 1rem;
      }
    }
  }

  .choosePaymentMethod {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 1rem;
      font-weight: 500;
      color: var(--c-primary);
      text-decoration: underline;
      text-underline-position: under;
      margin-bottom: 1rem;
    }

    .payPlatform {
      justify-content: flex-start;

      .payPlatformImage {
        display: flex;
        align-items: center;
        width: 10rem;
        height: 100%;
        padding-left: 1rem;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 1279px) {
  .part6 {
    & > div {
      & > div {
        .left {
          min-width: 35%;
        }
      }
    }
    .choosePaymentMethod {
      .payPlatform {
        flex-direction: row;
      }
    }
  }
}
