.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.filter {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;

  & > div {
    width: 30%;
  }
}

.swiperContainer {
  --swiper-navigation-color: var(--c-primary);
  --swiper-navigation-sides-offset: 0rem;
  --swiper-pagination-color: var(--c-primary);
  --swiper-pagination-bullet-inactive-color: var(--c-black);
  padding: 1rem 2rem;
}

.swiperPagination {
  --swiper-pagination-bottom: 0rem;
}

.swiperWrapper {
  flex-direction: row !important;
}

.servicesOuter {
  padding: 2rem 0;
}

.title {
  font-size: 1.5rem;
  color: var(--c-black);
  text-align: center;
  font-weight: 700;
}

.services {
  width: 100%;
  height: 100%;
  // display: grid;
  // gap: 0.5rem;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 3rem 0;

  .serviceField {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem;

    & > div {
      &:nth-child(1) {
        width: 6.5rem;
        height: 6.5rem;
        border-radius: 0.75rem;
        transition: all 0.5s ease;
        box-shadow: 0px 4px 16px 0px var(--c-shadow);
        overflow: hidden;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &:nth-child(2) {
        padding-top: 0.5rem;
        font-family: var(--ff-content);
        font-size: 0.8rem;
        color: var(--c-black);
        text-align: center;
        font-weight: 500;
      }
    }

    &:hover {
      & > div {
        &:nth-child(1) {
          transform: scale(1.05);
        }
      }
    }
  }
}

.swiperSlide {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: start;
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1279px) {
  .filter {
    justify-content: flex-start;
    margin-top: 2rem;
    margin-bottom: 0;
    margin-left: 1rem;

    & > div {
      width: 70%;
    }
  }

  .services {
    padding: 2rem 0;
  }

  .swiperSlide {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .swiperContainer {
    padding: 1rem 0;
  }
}
