.title {
  font-size: 2rem;
  color: var(--c-black);
  font-weight: 700;
  text-align: center;
}

.name {
  width: 100%;
}

.refLabel {
  color: var(--c-black);
  font-size: 1rem;
  font-weight: 600;
  padding: 0 !important;
}

.refLabel + div {
  padding-top: 0 !important;
}
@media (max-width: 1279px) {
  .name > div:nth-child(1) {
    padding-bottom: 2rem;
  }

  .refLabel {
    font-size: 0.8rem;
    padding: 0 !important;
  }
}
