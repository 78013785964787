.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.filter {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
  width: 60%;

  & > div {
    width: 30%;
  }
}

.locationsOuter {
  padding: 5rem 0 8rem;
  height: 100%;
  width: 100%;
  background: url('../../../../images/illus/locations.png'), #eef1fa;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  .Locations {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 2rem;
      font-weight: 700;
      padding: 2rem 0;
      color: var(--c-black);
    }

    .locationsBox {
      aspect-ratio: 1;
      width: 60%;
      border-radius: 1rem;
      border: 3px solid var(--c-primary);
      box-shadow: 0px 4px 16px 0px var(--c-shadow);
      overflow: hidden;
    }
  }
}

@media (max-width: 1279px) {
  .filter {
    justify-content: flex-start;
    margin-top: 2rem;
    margin-bottom: 0;
    margin-left: 1rem;
    width: 100%;

    & > div {
      width: 70%;
    }
  }

  .locationsOuter {
    padding: 2rem 0 4rem;

    .Locations {
      padding: 0 1rem;

      .locationsBox {
        aspect-ratio: 3/4;
        width: 100%;
        border-radius: 0.5rem;
        border: 2px solid var(--c-primary);
      }

      .title {
        padding: 1rem 0;
        font-size: 1.5rem;
      }
    }
  }
}
