.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.createAccountOuter {
  height: 100vh;
  width: 100%;
  background: url('../../../images/illus/signUp.png');
  background-repeat: no-repeat;
  display: flex;
}

.createAccount {
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.createAccountForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--c-primary-light);
  border-radius: 1rem;
  padding: 3rem 4rem;
  width: 40vw;
  max-height: 100%;

  &::-webkit-scrollbar {
    width: 0em;
  }

  & > div {
    width: 100%;

    & > div {
      padding: 1rem 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .logo {
    width: 6rem;
    height: 6rem;
    padding-bottom: 0;

    & > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .title {
    font-size: 2rem;
    color: var(--c-black);
    font-weight: 700;
    text-align: center;
  }

  .button {
    width: 100%;

    & > button {
      cursor: pointer;
      width: 100%;
      outline: none;
      border: none;
      background: var(--c-primary);
      border-radius: 0.5rem;
      color: var(--c-white);
      font-size: 1rem;
      font-weight: 700;
      margin-top: 0.5rem;
      padding: 0.75rem 0.5rem;
      text-align: center;
    }
  }

  .alreadyAccount {
    width: 100%;
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--c-black);
    font-size: 1rem;
    font-weight: 600;

    & > a {
      margin-left: 0.5rem;
      color: var(--c-primary);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.registerAsGuest {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  color: var(--c-black);
  transition: all 0.5s ease;
  text-align: center;

  &:hover {
    transform: scale(1.05);
  }

  & > span {
    color: var(--c-primary);
  }
}

@media (max-width: 1279px) {
  .createAccount {
    padding: 0 1rem;
    height: 90vh;
  }

  .createAccountForm {
    padding: 2rem 1rem;
    width: 100%;

    & > div > div {
      padding: 1rem 0;
    }

    .title {
      font-size: 1.5rem;
    }

    .button > button {
      padding: 0.5rem;
    }
  }
}
