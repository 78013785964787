.main {
  margin: 0;
  padding: 0;
  // height: 100%;
  width: 100%;
  position: sticky;
  top: 0;
  // backdrop-filter: blur(5px);
  background: var(--c-white);
  z-index: 5;
}

.header {
  width: 100%;
  padding: 1rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--c-primary);

  .title {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--c-black);
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1rem;
    position: relative;

    & > a {
      text-decoration: none;
      border-radius: 50%;
      width: 1.75rem;
      height: 1.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: all 0.5s ease;

      &:hover {
        transform: scale(1.05);
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .notifications,
    .subscription,
    .subscriptionAlt,
    .subscriptionHalt {
      background: var(--c-primary);
      color: var(--c-white);
      margin-right: 1rem;

      & > span {
        font-size: 1rem;
      }

      .notificationsNumber {
        position: absolute;
        z-index: 1;
        top: 0.1rem;
        right: 0.1rem;
        background: var(--c-red);
        transform: translate(50%, -50%);
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        font-size: 0.5rem !important;
        font-weight: 600;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .subscription {
      background: var(--c-green);
    }

    .subscriptionAlt {
      background: var(--message-yellow);
    }

    .subscriptionHalt {
      background: var(--c-red);
    }

    .editProfile {
      border-radius: 50%;
      border: 1px solid var(--c-primary);
      overflow: hidden;
    }

    .userAdminName {
      position: absolute;
      bottom: -1rem;
      font-size: 0.6rem;
      font-weight: 700;
      right: 0;
      text-transform: capitalize;
      white-space: nowrap;
      color: var(--c-black);
    }
  }
}

.childrenResponsive {
  display: none;
}

@media (max-width: 1279px) {
  .children {
    display: none;
  }

  .header {
    border-bottom: none;
    padding-bottom: 0.5rem;

    .profile {
      margin-right: 3rem;
    }
  }

  .childrenResponsive {
    display: block;
    padding: 0.5rem 0;
    border-bottom: 2px solid var(--c-primary);
  }
}
