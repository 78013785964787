.main {
  margin: 0;
  padding: 0;
}

.comingSoon {
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 10rem 0;
}
