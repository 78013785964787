.planCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.1rem solid var(--c-black);
  border-radius: 0.5rem;
  padding: 1rem;
  transition: all 0.5s ease;
  box-shadow: 0px 4px 16px 0px var(--c-shadow);
  height: 100%;
  cursor: pointer;

  &:hover {
    border: 0.1rem solid var(--c-primary);
    box-shadow: 0px 4px 16px 0px var(--c-primary);
  }

  & > div {
    max-width: 48%;
  }

  .left {
    display: flex;
    align-items: center;

    .radio {
      border-radius: 50%;
      width: 1rem;
      aspect-ratio: 1;
      border: 0.1rem solid var(--c-black);
      margin-right: 1rem;
      padding: 0.15rem;

      .active {
        background: var(--c-black);
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }

    .duration {
      font-size: 1.25rem;
      font-weight: 600;
      color: var(--c-black);
    }
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;

    .mrp,
    .discount {
      color: var(--c-font);
      font-size: 1rem;
      font-weight: 600;
      font-style: italic;
      padding: 0;
    }

    .discount {
      padding-left: 1rem;
    }

    .mrp {
      text-decoration: line-through;
      margin-right: 1rem;
    }

    .price {
      font-size: 1.75rem;
      font-weight: 700;
      color: var(--c-primary);
    }
  }
}

@media (max-width: 1279px) {
  .planCard {
    padding: 0.5rem;

    .left {
      .duration {
        font-size: 0.8rem;
      }
    }

    .right {
      .mrp,
      .discount {
        font-size: 0.8rem;
      }

      .price {
        font-size: 1.25rem;
      }
    }
  }
}
