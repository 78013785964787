.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.locationPicker {
  display: flex;
  position: relative;

  .suggestions {
    padding: 0.25rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    background: var(--c-white);
    box-shadow: 0.2rem 0.2rem 0.5rem var(--c-shadow);
    border: 0.05rem solid var(--c-primary);
    position: absolute;
    top: 2.25rem;
    min-width: 20vw;
    left: 0;
    z-index: 2;
    font-size: 0.8rem;
    max-height: 30vh;
    overflow: hidden;

    .suggestionsInner {
      height: 100%;

      &::-webkit-scrollbar {
        width: 0.3em;
      }
      &::-webkit-scrollbar-track {
        background-color: var(--c-border);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--c-primary);
      }

      & > div {
        cursor: pointer;
        // margin: 0.25rem 0;
        transition: all 0.5s ease;
        padding: 0.25rem 0.5rem;
        // margin-right: 0.25rem;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--c-primary-light);

        &:hover {
          background: var(--c-primary-light);
        }
      }
    }

    .suggestedCities:hover {
      color: var(--c-black);
      font-weight: 500;
    }

    .detectLocation {
      color: var(--c-primary);

      & > span {
        margin-right: 0.25rem;
        font-size: 0.8rem;
      }
    }
  }
}
