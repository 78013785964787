.main {
  margin: 0;
  padding: 0;
}

.termsAndConditionsSection {
  height: 100%;
  width: 100%;
  padding: 1rem 2rem;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: var(--c-primary);
  padding: 1rem 0;
}

.termsAndConditions {
  font-size: 1rem;
  font-weight: 500;
  color: var(--c-black);
  text-align: justify;
  white-space: pre-wrap;

  & > ul {
    padding: 0;

    & > li {
      margin: 0 2rem;
    }
  }
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}
