.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 1.5rem 0;

  .searchField {
    width: 60%;
    display: flex;
    align-items: center;

    .fields > div:nth-child(2) {
      display: none;
    }

    & > div:first-child {
      padding-right: 1rem;
      width: 45%;
    }
  }
}

.searchOuter {
  height: 100%;
  width: 100%;
}

.search {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.adsSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .title {
    font-size: 1.2rem;
    color: var(--c-black);
    font-weight: 700;
    padding-top: 1rem;
  }

  .ads {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }
}

@media (max-width: 1279px) {
  .search {
    padding: 0 0.75rem;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0;

    & > div:nth-child(2) {
      display: none;
    }

    .searchField {
      width: 100%;
      flex-direction: column-reverse;
      align-items: flex-start;

      & > div:first-child {
        padding-right: 0rem;
        width: 100%;
      }

      .fields {
        padding-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
          width: 48%;

          &:nth-child(2) {
            display: flex;
          }
        }
      }
    }
  }
}
