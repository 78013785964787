.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.topScroll {
  position: fixed;
  z-index: 4;
  right: 2rem;
  bottom: 2rem;
  background: var(--c-primary);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  border: 2px solid var(--c-white);
  cursor: pointer;
  box-shadow: 0px 4px 16px 0px var(--c-shadow);
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.05);
  }

  & > span {
    color: var(--c-white);
  }
}

@media (max-width: 1279px) {
  .topScroll {
    right: 1rem;
  }
}
