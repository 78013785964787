.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.trending {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--c-black);
    padding-bottom: 2rem;
  }

  .trendingFields {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;

    .trendingField {
      display: flex;
      flex-direction: column;
      // background: url('../../../../images/illus/back3.png'), var(--c-white);
      background: var(--c-white);
      background-size: cover;
      border-radius: 1rem;
      border: 0.1rem solid var(--c-primary);
      box-shadow: 0px 4px 10px 1px var(--c-shadow);
      padding: 1rem;

      .fieldHeading {
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--c-black);
        margin-bottom: 1rem;
        cursor: pointer;
      }

      .subFields {
        width: 100%;
        height: 100%;
        display: grid;
        gap: 1.5rem;
        grid-template-columns: 1fr 1fr 1fr;

        .subField {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          transition: all 0.5s ease;

          & > div {
            &:nth-child(1) {
              width: 8rem;
              // height: 8rem;
              aspect-ratio: 1.25;
              border-radius: 0.75rem;
              transition: all 0.5s ease;
              border: 2px solid var(--c-primary);
              overflow: hidden;

              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            &:nth-child(2) {
              padding-top: 0.5rem;
              font-family: var(--ff-content);
              font-size: 0.9rem;
              color: var(--c-black);
              text-align: center;
              font-weight: 600;
            }
          }

          &:hover {
            & > div {
              &:nth-child(1) {
                box-shadow: 0px 4px 5px 1px var(--c-primary);

                transform: scale(1.05);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1279px) {
  .trending {
    padding: 2rem 1rem;

    .trendingFields {
      grid-template-columns: 1fr;

      .trendingField {
        .subFields {
          gap: 0.5rem;

          .subField > div {
            &:nth-child(1) {
              // width: 5.75rem;
              width: 100%;
            }
            &:nth-child(2) {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
}
