.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.contactUs {
  height: 100%;
  width: 100%;
  padding: 5rem;

  .contactForm {
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: 2rem;
    background: var(--c-white);
    box-shadow: 0px 4px 50px 0px var(--c-shadow);
    overflow: hidden;

    .left {
      width: 55%;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .header {
      height: 15%;
      width: 100%;

      & > div {
        &:nth-child(1) {
          font-size: 2rem;
          color: var(--c-black);
          font-weight: 600;

          & > span {
            color: var(--c-primary);
          }
        }
        &:nth-child(2) {
          font-size: 0.8rem;
          color: var(--c-black);
          font-family: var(--ff-content);
          font-weight: 500;
        }
      }
    }

    .form {
      height: 75%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem 0;

      & > div {
        padding-top: 1rem;

        &:first-child {
          padding-top: 0;
        }
      }

      .button {
        & > button {
          cursor: pointer;
          width: 100%;
          outline: none;
          border: none;
          background: var(--c-primary);
          border-radius: 0.5rem;
          color: var(--c-white);
          font-size: 0.8rem;
          font-weight: 500;
          padding: 0.75rem 0.5rem;
          text-align: center;
        }
      }
    }

    .bottomOuter {
      height: 100%;
      width: 100%;
    }

    .bottom {
      display: flex;
      justify-content: space-between;

      & > a {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 0.8rem;
        cursor: pointer;
        text-decoration: none;

        & > span {
          color: var(--c-black);
          font-size: 1.75rem;
        }
      }

      .phone,
      .email {
        & > div {
          padding-left: 0.5rem;

          & > div {
            &:first-child {
              color: var(--c-black);
              font-weight: 600;
            }
            &:last-child {
              color: var(--c-primary);
            }
          }
        }
      }
    }

    .address {
      display: flex;
      flex-direction: column;
      height: 100%;
      font-size: 0.8rem;
      margin-top: 0.5rem;

      & > div {
        &:first-child {
          color: var(--c-black);
          font-weight: 600;
          display: flex;
          align-items: center;
          font-size: 0.8rem;
          margin-bottom: 0.25rem;

          & > span {
            color: var(--c-black);
            font-size: 1.5rem;
          }
        }

        &:last-child {
          color: var(--c-primary);
          padding-left: 0.5rem;
          text-align: justify;
          font-size: 0.6rem;
        }
      }
    }

    .right {
      width: 45%;
      // height: 100%;
      padding: 2rem 0;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 60%;
        top: 0;
        right: 0;
        background: var(--c-primary);
      }

      .location {
        // aspect-ratio: 3/4.25;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1279px) {
  .contactUs {
    padding: 2rem 1rem;

    .contactForm {
      flex-direction: column;

      .left {
        padding: 2rem 1.5rem;
      }

      .right,
      .left {
        width: 100%;
      }

      .right {
        padding: 2.5rem 0;

        &::after {
          width: 100%;
        }

        .location {
          aspect-ratio: 3/4.25;
          width: 100%;
          // height: 100%;
        }
      }

      .bottom > a > span {
        color: var(--c-black);
        font-size: 1.25rem;
      }
    }
  }
}
