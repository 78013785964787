.outer {
  position: relative;
  transition: all 0.5s ease;
  border-radius: 1rem;

  &:hover {
    transform: scale(1.05);

    .serviceField {
      background: var(--c-primary);
      color: var(--c-white);
    }

    .icon {
      color: var(--c-primary);
    }

    .activeIcon {
      color: var(--c-green) !important;
    }
  }
}

.serviceField {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  border: 0.1rem solid var(--c-primary);
  overflow: hidden;
  cursor: pointer;
  color: var(--c-black);
  transition: all 0.5s ease;
  border-top-right-radius: 0;

  & > div {
    &:nth-child(1) {
      width: 4rem;
      height: 4rem;
      border-radius: 1rem;
      overflow: hidden;
      border: 0.1rem solid var(--c-white);

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:nth-child(2) {
      width: 70%;
      font-size: 0.8rem;
      padding: 0 0.5rem;
      font-weight: 600;
    }
  }
}

.active {
  background: var(--c-primary) !important;
  color: var(--c-white) !important;
}

.icon {
  transition: all 0.5s ease;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -0.25rem;
  right: -0.25rem;
  color: var(--c-primary);
  border-radius: 50%;
  background: var(--c-white);

  & > span {
    font-size: 1rem;
  }
}

.activeIcon {
  color: var(--c-green);
}

@media (max-width: 1279px) {
  .outer {
    &:hover {
      transform: scale(1);

      .serviceField {
        color: var(--c-black);
        background: var(--c-white);
      }
    }
  }
}
