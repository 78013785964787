.mainOuter {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  backdrop-filter: blur(5px);
  top: 0;
}

.main {
  margin: 0;
  padding: 0;
  position: sticky;
  height: 10vh;
  top: 90vh;
  z-index: 3;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--c-white);
  background: var(--c-primary);
  border: 2px solid var(--c-white);
  border-bottom: none;
  box-shadow: 0px 4px 16px 0px var(--c-shadow);
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  height: 100%;
  padding: 1rem 0.5rem;
  font-size: 1rem;
  font-weight: 600;

  .close {
    cursor: pointer;
    background: var(--c-white);
    color: var(--c-primary);
    padding: 0.25rem 1rem;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    font-weight: 700;
    border-radius: 0.5rem;
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  // .actionButtons {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;

  //   & > div {
  //     margin-left: 1.5rem;
  //   }
  // }
}
