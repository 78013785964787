.main {
  margin: auto;
  background: var(--c-orange-grad);
}

.footer {
  max-width: 1280px;
  margin: auto;
  color: var(--c-white);
  font-family: var(--ff-content);
  font-weight: 500;
  font-size: 0.9rem;
  padding-top: 1rem;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  // border-bottom: 0.1rem solid var(--c-white);

  &:last-child {
    justify-content: flex-end;
    border: none;
    font-size: 0.8rem;
  }
}

.column {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  width: 20%;

  &:nth-child(1) {
    width: 30%;
  }
  &:nth-child(2) {
    padding: 0 2rem;
  }
}

.image {
  & > a {
    display: flex;
    justify-content: center;
    padding: 0rem;
    height: 5rem;
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.title {
  font-weight: 800;
  font-size: 1.2rem;
  font-family: var(--ff-title);
  // text-align: center;
  padding: 1.5rem 0;
}

.content {
  text-align: justify;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;

  & > a,
  & > div {
    padding: 0;
    margin: 0.25rem 0;
    color: var(--c-white);
  }

  & > a {
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  & > div {
    margin: 0.25rem 0;
    display: flex;

    & > a {
      color: var(--c-white);
    }

    &:nth-child(2) {
      padding: 0.5rem 0;
      // border-bottom: 0.1rem solid var(--c-white);
    }
  }
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}

.socialIcons {
  justify-content: space-between;

  & > a {
    display: flex;
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }

    & > img {
      height: 1.75rem;
    }
  }
}

@media (max-width: 1279px) {
  .row {
    flex-direction: row;
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 90%;
    padding: 1rem 0;
    border-bottom: 0.1rem solid var(--c-white);

    &:nth-child(1) {
      width: 90%;
    }
    &:nth-child(2) {
      padding: 1rem;
    }
  }

  .image > a {
    height: 6rem;
  }

  .socialIcons {
    padding: 1rem 0 !important;

    & > a > img {
      height: 2rem;
    }
  }

  .title,
  .content {
    text-align: center;
  }

  .content > a:hover,
  .socialIcons > a:hover {
    transform: scale(1);
  }
}
