.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 1.5rem 2rem;

  .searchField {
    width: 60%;
    display: flex;
    align-items: center;

    & > div:first-child {
      padding-right: 1rem;
      width: 45%;
    }
  }

  .invite {
    // width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: var(--c-primary);
    font-style: italic;
    font-size: 1rem;
    font-family: var(--ff-content);
    cursor: pointer;

    & > span {
      display: flex;
      align-items: center;

      & > span {
        padding-left: 0.5rem;
      }

      &:nth-child(2) {
        font-size: 0.8rem;
      }
    }
  }

  .inviteResponsive {
    display: none;
  }
}
.banners,
.businessProducts {
  height: 100%;
  width: 100%;
  padding: 2rem 0;

  .slide {
    width: 100%;
    // max-width: 14rem;
    aspect-ratio: 1;
    cursor: grab;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.businessInteractions {
  display: flex;
  justify-content: space-between;

  & > a,
  & > div {
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--c-white);
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }

    .icon {
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;

      & > img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .iconSpan {
      font-size: 1rem;
    }

    & > span {
      font-size: 0.8rem;
      padding-right: 0.25rem;

      // &:last-child {
      //   padding-right: 0;
      //   padding-left: 0.25rem;
      // }
    }

    &:last-child {
      & > span {
        padding-right: 0rem;
        padding-left: 0.25rem;
      }
    }
  }

  .phoneNo,
  .views {
    background: var(--c-blue);
  }

  .whatsapp,
  .status {
    background: var(--c-green);
  }

  .sendInterest,
  .leads {
    background: var(--c-orange);
  }

  .viewMore,
  .viewLeads {
    background: var(--c-black);
  }

  .share {
    display: flex;
    align-items: center;
    color: var(--c-primary);
    font-style: italic;
    font-size: 1rem;
    font-family: var(--ff-content);
    cursor: pointer;

    & > span {
      font-size: 1rem;
      padding-left: 0.5rem;
    }
  }
}

.directions {
  display: flex;
  align-items: center;
  color: var(--c-red);
  font-size: 1rem;
  margin-top: 2rem;
  cursor: pointer;

  & > span {
    margin-right: 0.25rem;
    font-size: 1rem;
  }
}

.tags {
  display: flex;
  padding: 2rem 0;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .tag {
    padding: 0.5rem;
    color: var(--c-font);
    font-size: 1rem;
    font-weight: 600;
  }
}

.businessName {
  font-size: 1.2rem;
  color: var(--c-black);
  text-decoration: underline;
  text-transform: capitalize;
  font-weight: 600;
}

.userProfileLink {
  font-size: 0.8rem;
  color: var(--c-primary);
  text-decoration: underline;
  font-weight: 600;
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.address {
  font-size: 0.8rem;
  color: var(--c-black);
  font-weight: 500;
}

.description {
  color: var(--c-black);
  font-size: 1rem;
  font-weight: 500;
  font-style: italic;
  text-align: justify;
  margin: 1rem 0;
}

.businessTimelines {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: var(--c-black);
  padding-bottom: 1rem;

  .title {
    font-size: 1rem;
    font-weight: 600;
    color: var(--c-black);
  }

  .workingHours {
    .workingDay {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .day {
        font-weight: 600;
        font-size: 1rem;
        color: var(--c-black);
        min-width: 10rem;
      }

      .dayWorkingHours {
        color: var(--c-primary);
        font-weight: 500;
      }
    }
  }
}

.socials {
  padding: 2rem 0;
  display: flex;
  align-items: center;

  & > span {
    font-size: 1rem;
    font-weight: 600;
    color: var(--c-black);
    margin-right: 1rem;
  }

  & > a {
    .image {
      display: flex;
      align-items: center;
      width: 1.75rem;
      height: 1.75rem;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &:last-child {
      margin-left: 0.5rem;

      .image {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.swiperContainer {
  padding: 2rem;
}

.businessProducts {
  .slide {
    cursor: pointer;
    flex-direction: column;

    & > img {
      width: 90%;
      height: 90%;
    }

    .listingName {
      font-size: 1rem;
      font-weight: 600;
      color: var(--c-black);
      text-transform: capitalize;
      padding-top: 0.5rem;
    }
  }

  .title {
    text-align: center;
    font-size: 1.2rem;
    padding: 1rem 0;
    font-weight: 600;
    color: var(--c-black);
  }
}

@media (max-width: 1279px) {
  .header {
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;

    .searchField {
      width: 100%;
      flex-direction: column;

      & > div:first-child {
        padding-right: 0;
        margin: 1rem 0;
        width: 100%;
        align-self: start;
      }
    }

    .invite {
      display: none;
    }

    .fields {
      display: flex;
      justify-content: space-between;

      & > div {
        width: 48%;
      }
    }

    .inviteResponsive {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      color: var(--c-primary);
      font-style: italic;
      font-size: 0.75rem;
      font-family: var(--ff-content);
      cursor: pointer;

      & > span {
        display: flex;
        align-items: center;

        & > span {
          font-size: 1rem;
          padding-left: 0.25rem;
        }

        &:nth-child(2) {
          font-size: 0.7rem;
        }
      }
    }
  }

  .businessProfile {
    padding: 0 0.75rem;
  }

  .banners {
    padding: 1rem 0;
  }

  .businessInteractions {
    & > a,
    & > div {
      padding: 0.25rem;
      font-size: 0.55rem;

      & > span {
        font-size: 0.55rem;
        padding-right: 0.2rem;
      }

      .icon {
        width: 0.6rem;
        height: 0.6rem;
        margin-right: 0.2rem;
      }

      .iconSpan {
        font-size: 0.75rem;
      }
    }

    .share {
      font-size: 0.75rem;

      & > span {
        font-size: 0.75rem;
      }
    }
  }

  .tags {
    padding: 1rem 0;
  }

  .businessTimelines {
    flex-direction: column;
  }

  .dateOfEstablishment {
    margin-top: 1.5rem;
  }
}
