@import 'src/styles/mixins';

.plansSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--c-white);
  border-radius: 1rem;
  padding: 3rem 7rem;
  max-width: 1080px;
  width: 100%;
  max-height: 100%;
  position: relative;
  padding-top: 5rem;
  height: unset;

  &::-webkit-scrollbar {
    width: 0em;
    height: 0.6em;
  }

  .title {
    font-size: 2rem;
    font-weight: 700;
    color: var(--c-black);
    width: 100%;
    text-align: center;
    border-right: none;
    border-left: none;
  }

  .info {
    color: var(--c-black);
    font-size: 1.2rem;
    font-weight: 700;
    margin-top: 1rem;
  }

  .plans {
    width: 100%;
    border: 0.02rem solid var(--c-black);
    border-style: dashed;
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      margin: 0.5rem 0;
    }
  }

  .registerAsGuest {
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--c-black);
    margin-top: 1rem;
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .planInfos {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;

    .planInfo {
      display: flex;
      align-items: center;
      color: var(--c-black);
      font-size: 1rem;
      font-weight: 500;
      padding: 0.25rem 0;

      & > span {
        color: var(--c-primary);
        margin-right: 1rem;
      }
    }
  }

  .payButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    .button {
      @include buttonStyle;
      border: 0.1rem solid var(--c-black);
      border-radius: 0.5rem;
      margin-top: 1rem;
      font-weight: 700;
      background: var(--c-black);
      color: var(--c-white);

      &:hover {
        transform: scale(1.05);
      }

      & > span {
        padding-left: 0.5rem;
      }
    }
  }
}

@media (max-width: 1279px) {
  .plansSection {
    padding: 2rem 1rem;
    width: 100%;
    justify-content: flex-start;
    padding-top: 4rem;

    .info {
      margin-top: 2rem;
    }
  }
}
