.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.swiperContainer {
  --swiper-navigation-color: var(--c-white);
  --swiper-navigation-sides-offset: 0rem;
  --swiper-pagination-color: var(--c-white);
  --swiper-pagination-bullet-inactive-color: var(--c-black);
  padding: 1rem 2rem;
}

.swiperPagination {
  --swiper-pagination-bottom: 0rem;
}

.successStoriesOuter {
  width: 100%;
  height: 100%;
  background: url('../../../../images/illus/back4.png');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  margin-top: 3rem;

  .successStories {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    max-width: 1360px;

    .title {
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--c-white);
      padding-bottom: 2rem;
    }

    .successStoriesData {
      width: 100%;
      height: 100%;
      // display: grid;
      // gap: 1.5rem;
      // grid-template-columns: 1fr 1fr 1fr;

      .successStory {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: var(--c-white);
        border-radius: 1rem;
        border: 3px solid var(--c-primary);
        background: var(--c-white-light);
        // box-shadow: 0px 4px 16px 0px var(--c-shadow);
        backdrop-filter: blur(5px);
        padding: 1rem;
        position: relative;
        // max-width: 18.75rem;
        // max-width: 20.5vw;
        font-size: 1rem;

        .quotes,
        .quotesLast {
          width: 1.5rem;
          height: 1.5rem;
          align-self: flex-start;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .quotesLast {
          transform: scale(-1);
          align-self: flex-end;
        }

        .story {
          padding: 0.5rem;
          font-family: var(--ff-content);
          font-size: 0.8rem;
          color: var(--c-black);
          text-align: justify;
        }

        .storyUser {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;

          .userImage {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            border: 0.05rem solid var(--c-primary);
            overflow: hidden;

            & > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .user {
            padding-left: 1rem;

            & > div {
              &:nth-child(1) {
                color: var(--c-black);
                font-weight: 700;
                font-size: 1rem;
              }
              &:nth-child(2) {
                color: var(--c-primary);
                font-weight: 500;
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}

.loader {
  display: flex;
  height: 100%;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1279px) {
  .successStoriesOuter {
    margin-top: 2rem;

    .successStories {
      padding: 0 1rem;

      .successStoriesData {
        grid-template-columns: 1fr;

        .successStory {
          backdrop-filter: none;
          max-width: 100%;
        }
      }
    }
  }
}
