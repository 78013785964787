@import 'src/styles/mixins';

.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.loader {
  display: flex;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}

.settings {
  padding: 0 1rem;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  padding-bottom: 4rem;
}

.planInfoCard {
  transition: all 0.5s ease;
  background: var(--c-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--c-white);
  width: 30vw;
  padding: 2rem;
  font-size: 1rem;
  font-weight: 600;

  & > div {
    width: 49%;
  }

  .left {
    display: flex;
    flex-direction: column;

    .info {
      font-size: 1rem;
      font-weight: 600;
      color: var(--c-white);
      margin-bottom: 1rem;
    }

    & > a {
      text-decoration: none;
      background: var(--c-white);
      color: var(--c-black);
      width: fit-content;
      font-size: 1rem;
      font-weight: 700;
      padding: 0.5rem 1rem;
      transition: all 0.5s ease;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .right {
    .img {
      width: 100%;
      aspect-ratio: 1;
      background: var(--c-white);

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 1279px) {
  .settings {
    padding: 0 0.5rem;
  }

  .content {
    padding: 0.5rem;
  }

  .planInfoCard {
    width: 100%;
    padding: 1.5rem;
  }
}
