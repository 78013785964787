.successPage {
  height: 100vh;
  width: 100%;
  background: url('../../../images/illus/signUp.png');
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.successPageInner {
  background: var(--c-white);
  width: 40vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
  padding: 2rem 3rem;

  & > div {
    padding: 1rem 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-bottom: 2.1px solid var(--c-font-light);
  border-bottom-style: dashed;
  padding-top: 0 !important;

  .logo {
    width: 7rem;
    padding-bottom: 1rem;

    & > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--c-black);
    padding-top: 1rem;
  }

  .headerTop {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      padding-top: 0;
      margin-right: 1rem;
    }
  }

  .successIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--c-green-light);
    padding: 0.75rem;
    animation: success 1s linear;
    transition: all 0.5s ease;

    & > span {
      color: var(--c-green);
      font-size: 1.75rem;
    }
  }
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-bottom: 2.1px solid var(--c-font-light);
  border-bottom-style: dashed;

  .info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 0;

    & > div:nth-child(2) {
      color: var(--c-black);
      font-weight: 500;
    }
  }

  .name {
    & > div:nth-child(2) {
      font-weight: 600;
    }
  }

  .payMethod {
    padding-bottom: 1.5rem;
  }

  .amount {
    font-size: 1.2rem;
    border-top: 2.1px solid var(--c-font-light);
    padding-top: 1.5rem;
    border-top-style: dashed;

    & > div:nth-child(2) {
      font-weight: 600;
    }
  }
}

.actionButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0 !important;

  .downloadReceipt,
  .continue {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 600;
    color: var(--c-black);
    border: 0.1rem solid var(--c-primary);
    border-radius: 1rem;
    transition: all 0.5s ease;
    cursor: pointer;
    padding: 0.5rem 1rem;

    & > span {
      font-size: 1.5rem;
      padding-right: 0.5rem;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .continue {
    background: var(--c-primary);
    color: var(--c-white);
  }
}

.printReceipt {
  transform: translate(-100%, -100%);
  position: absolute;
  z-index: -1;
}

.receiptDownload {
  align-items: center;
  width: 100% !important;
  padding: 42px 63px !important;

  & > div {
    padding: 21px 0 !important;
  }

  .header {
    padding-top: 0 !important;

    .logo {
      width: 147px !important;
      padding-bottom: 21px !important;
    }

    .successIcon {
      animation: none !important;
      padding: 15.75 !important;

      & > span {
        font-size: 36.75px !important;
      }
    }

    .title {
      font-size: 31.5px !important;
      padding-top: 21px !important;
    }

    .headerTop {
      .title {
        padding-top: 0 !important;
        margin-right: 21px !important;
      }
    }
  }

  .body {
    .info {
      font-size: 21px !important;
      padding: 10.5px 0 !important;
    }

    .payMethod {
      padding-bottom: 31.5px !important;
    }

    .amount {
      padding-top: 31.5px !important;
      font-size: 25.2px !important;
    }
  }
}

.loader {
  display: flex;
  height: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}

@keyframes success {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
}

@media (max-width: 1279px) {
  .successPageInner {
    padding: 2rem 1rem;
    width: 90%;
  }

  .actionButtons {
    .downloadReceipt,
    .continue {
      font-size: 0.8rem;
      padding: 0.25rem 1rem;
    }
  }
}
